#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

/* ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
} */

button {
  margin: 0;
  padding: 0;
  border: none;
}

h1.h2.h3.h4.h5.h6 {
  color: #212122;
}

/* Header  */

.navbar {}

/* .navbar-brand img {
  height: 55px;
} */

.navbar .nav-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #212122;
  padding: 7px 14px !important;
  position: relative;
  transition: all .2s ease-in-out;
  border-radius: 20px;
}

.navbar .nav-link:hover,
.navbar .nav-link.active {
  background: #D9EFFA;
  color: #000;
  transition: all .2s ease-in-out;
}

.product-dropdown {
  cursor: default;
  position: absolute;
  top: 25px;
  left: -160px;
  width: 400px;
  border-radius: 10px;
  z-index: 11;
  visibility: hidden;
  -webkit-transition: max-height 0.2s ease-in;
  transition: max-height 0.2s ease-in;
  max-height: 0;
  overflow: hidden;
}

.navbar .nav-link:hover .product-dropdown {
  visibility: visible;
  -webkit-transition: max-height 0.1s ease-in;
  transition: max-height 0.1s ease-in;
  max-height: 650px;
}

.product-dropdown .products {
  background: #FFFFFF;
  z-index: 11;
  border: 1px solid #E2E2E2;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px 40px;
  margin: 30px;
  margin-bottom: 50px;
}

.product-dropdown h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(33, 33, 34, 0.6);
  text-align: left;
  margin-bottom: 35px;
}

.products ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.products ul li img {
  height: 26px;
  margin-right: 12px;
}

.products ul li {
  margin-bottom: 40px;
  text-transform: none;
}

.products ul li:last-child {
  margin-bottom: 20px;
}

.connect-outline-btn {
  background: transparent;
  display: inline-flex;
  width: 120px;
  height: 31px;
  border: 2px solid #000000;
  border-radius: 20px;
  align-items: center;
  font-weight: 700;
  color: #000;
  font-size: 14px;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.connect-outline-btn:hover {
  background: #000;
  color: #fff;
  transition: all .3s ease-in-out;
}

.navbar-collapse.collapsing {
  transition: none !important;
}

.connect-btn {
  background: black;
  display: inline-flex;
  padding: 2px 25px;
  height: 40px;
  border: 2px solid #000000;
  border-radius: 20px;
  align-items: center;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.connect-btn:hover {
  background: transparent;
  color: #000;
  border: 2px solid #000000;
  transition: all .3s ease-in-out;
}

/* Banner  */

.banner {
  background: url(../public/assets/main-bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.banner-content h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
  text-align: center;
  text-transform: uppercase;
  color: #020202;
}

.banner-content p {
  font-weight: 400;
  font-size: 26px;
  line-height: 41px;
  text-align: center;
  color: #020202;
  opacity: 0.8;
}

.trustedBy h6 {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 100px 0 50px 0;
  text-align: center;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  background: linear-gradient(90.56deg, #CD01FF 9.19%, #40BBFF 114.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.multi-dashboard h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: #020202;
  margin: 100px 0 50px 0;

}

.multi-dashboard h5 {
  margin: 0px 0 30px 0;
  font-weight: 300;
  font-size: 48px;
  line-height: 58px;
  background: linear-gradient(127.63deg, #000000 4.88%, #01A4FF 62.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.multi-dashboard p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #020202;
  opacity: 0.8;
}

.bg-dashboard {
  background: rgba(244, 247, 253, 0.6);
  backdrop-filter: blur(12px);
  padding: 100px 0;
}

.box {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 20px;
  margin-bottom: 40px;
}

.box .col-md-5 {
  padding: 25px 10px 25px 45px;
}

.box .icon {
  margin-top: 10px;
  margin-bottom: 20px;
}

.box h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #020202;
  margin-bottom: 20px;
}

.box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #020202;
  opacity: 0.8;
  margin-bottom: 20px;
}

.box ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #020202;
  opacity: 0.8;
  margin-bottom: 15px;
}

.testimonial {
  background: url(../public/assets/testimonial-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px 0 100px 0;
  margin-top: 100px;
  position: relative;
}
.testimonial .moon{
  position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    top: -35px;
}

.test-btn {
  text-align: center;
  z-index: 1;
  position: relative;
  top: -20px;
  margin-bottom: 120px;
}

.testimonial h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 50px;
}

.container-fluid {
  max-width: 1420px;
  margin: 0 auto;
}

.swiper-slide {
  height: auto !important;
}

.testimonial .review-card {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 14px 64px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  padding: 40px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.review-card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.03em;
  color: #353535;
  margin-bottom: 40px;
  flex: 1;
}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}



.blog-bg,
.about-bg {
  background: url(../public/assets/main-bg.png);
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.blog-bg .first-bg {
  padding: 120px 0;

}

.blog-bg .first-content .heading {
  font-weight: 600;
  font-size: 40px;
  color: #020202;
}

.blog .first-content .para {
  font-weight: 400;
  font-size: 16px;
  color: #020202;
}

.blog-bg .first-content .team {
  font-weight: 400;
  font-size: 16px;
  color: #020202;
}

.blog-bg .first-content .date {
  font-weight: 400;
  font-size: 14px;
  color: rgba(2, 2, 2, 0.6);
}

.browse-section {
  background: rgba(244, 247, 253, 0.6);
  backdrop-filter: blur(12px);
}

.blog-bg .browse-section .browse-heading {
  color: #020202;
  font-size: 30px;
  font-weight: 600;
}

.blog .browse-section .browse-text {
  color: #020202;
  font-size: 16px;
  font-weight: 400;
}

.blog-bg .browse-section .filters a {
  border: 1px solid #000000;
  border-radius: 20px;
  width: 174px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  transition: all .3s ease-in-out;

}

.blog-bg .browse-section .filters a:hover,
.blog-bg .browse-section .filters a.active {
  color: #fff;
  background-color: black;
  transition: all .3s ease-in-out;
}

.blog-card .blog-img {
  height: 210px;
}

.blog-card .blog-img img {
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.blog-bg .blog-card .card-content {
  font-weight: 400;
  font-size: 18px;
  color: #020202;
  padding: 30px;

}

.blog-bg .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-card {
  border-radius: 20px 20px 20px 20px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all .3s ease-in-out;
  height: 100%;
}

.blog-card:hover {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  transition: all .3s ease-in-out;
}

.career {
  background: url(../public/assets/main-bg.png);
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 125px 0;
}

.career .career-heading {
  font-weight: 700;
  font-size: 48px;
  color: #020202;
  text-align: center;
}

.career .career-content {
  font-weight: 400;
  font-size: 26px;
  color: #020202;
  text-align: center;
}

.career-width {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

.career-card {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 30px;
  transition: all .3s ease-in-out;

}

.career-card:hover {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  transition: all .3s ease-in-out;

}

.career-card .heading {
  font-weight: 600;
  font-size: 30px;
  color: #020202;
}

.career-card .remote-text {
  font-weight: 400;
  font-size: 16px;
  color: #020202;
  opacity: 0.8;
}

.work-border {
  background: linear-gradient(93.34deg, #000000 3.05%, #E16AFE 31.24%, #2C45CF 67.49%);
  padding: 1px;
  border-radius: 20px;
  height: 230px;
}

.work-border .work-card {
  background: #FFFFFF;
  border-radius: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.work-card .work-content {
  flex: 1;
  padding: 20px 40px;
}

.work-card .work-content .heading {
  background: linear-gradient(91.33deg, #CD00FF 7.81%, #00A4FF 51.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.btnOutline {
  border: 1px solid #000000;
  height: 47px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 11px 27px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  border-radius: 40px;
  transition: all .2s ease-in-out;
}

.btnOutline:hover {
  background-color: #000000;
  color: #fff;
  transition: all .2s ease-in-out;

}

.work-card .logo-section {
  width: 279px;
  background-color: #0c0c0e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 0 20px 20px 0;
}
.connect-btn.demo:hover{
  background: #fff;
}
.footer {
  background: #000;
  padding: 75px 0;
}

.footer .social-icons a {
  width: 44px;
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(0deg, #505050, #505050), #505050;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  transition: all .3s ease-in-out;
}

.footer .social-icons a:hover {
  background: #fff;
  color: #000;
  transition: all .3s ease-in-out;
}

.footer .nav-links a {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
  position: relative;
}

.footer .nav-links a:hover {
  color: #D9EFFA;
  transition: all .2s ease-in-out;
}

.footer .nav-links a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  height: 2px;
  width: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #D9EFFA;
  transition: all .2s ease-in-out;
}

.footer .nav-links a:hover::before {
  color: #D9EFFA;
  width: 100%;
  transition: all .2s ease-in-out;
}

.footer .launch-btn {
  height: 31px;
  border-radius: 44px;
  background: #fff;
  padding: 8px 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #212122;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #fff;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}

.footer .launch-btn:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  transition: all .3s ease-in-out;
}

.footer .foot-para {
  color: #777B80;
  font-size: 16px;
  font-weight: 400;
  max-width: 730px;
  width: 100%;
}

.footer .copyright-text {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.footer .input-box input {
  padding: 12px;
  padding-right: 70px;
  width: 100%;
  height: 31px;
  background: #505050;
  border: 1px solid #777B80;
  border-radius: 50px;
  outline: none;
  color: #fff;
  font-size: 14px;
}

.footer .input-box .launch-btn {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
}

.footer .w-35 {
  width: 40%;
}

.about-bg {
  padding: 120px 0;
}

.about-bg .about-heading {
  font-weight: 700;
  font-size: 48px;
  color: #020202;
  text-align: center;
}

.about-bg .about-content {
  color: #020202;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  opacity: 0.8;
}

.about-bg .about-content span {
  background: linear-gradient(90.56deg, #CD01FF 16.34%, #40BBFF 30.65%), #020202;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.about-card {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 20px;
  padding: 30px;
}

.about-card .about-content {
  color: #020202;
  opacity: 0.8;
  font-weight: 400;
  font-size: 18px;
  text-align: start;
}

.or-text {
  font-weight: 700;
  font-size: 14px;
  color: #000;
}

.modal-header {
  border-bottom: none;
}

.modal-heading {
  background: linear-gradient(90.56deg, #CD01FF 9.19%, #40BBFF 114.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  letter-spacing: .03em;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 32px;
  text-align: center;
  font-weight: 700;
}

.modal .input-box input {
  background: rgba(38, 44, 72, .1);
  color: #272d4a;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 16px;
  padding: 12px 19px;
  outline: none;
  border: 1.5px solid rgba(38, 44, 72, .15);
  width: 100%;
}
.modal-backdrop{
  background-color: #00000073;
}

.modal .input-box textarea {
  background: rgba(38, 44, 72, .1);
  color: #272d4a;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 16px;
  padding: 12px 19px;
  outline: none;
  border: 1.5px solid rgba(38, 44, 72, .15);
  resize: none;
  width: 100%;
  height: 120px;
}

.modal input[type="checkbox"] {
  width: 22px;
  height: 22px;
}

.modal-content {
  background: #ffffffd1;
  backdrop-filter: blur(60px);
  border-radius: 25px;
}

.modal-dialog{
  border-radius: 30px;
 background: transparent;
}
.btn-close:focus{
    box-shadow: none !important;
}

.modal-body {
  padding: 0 60px;
  padding-bottom: 60px;
}

.modal .policy-text {
  color: #212122;
  font-size: 16px;
}

.modal .policy-text a {
  color: #3bf;
}

.modal .submit-btn {
  background: linear-gradient(92.27deg, #3bf, #0af 48.96%, #1f7099);
  padding: 10px 30px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: 40px;
}
.blend{
  mix-blend-mode: luminosity;
}
.blend1{
  mix-blend-mode: difference;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 490px !important;
  }
}

@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1020px;
  }

  .navbar-toggler {
    font-size: 36px;
    border: none;
    background: none;
  }

}

@media(max-width: 767px) {
  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #fff;
    z-index: 111;
    display: block !important;
  }

  .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #fff;
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #000;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-brand img {
    height: 40px;
  }

  .banner-content h1 {
    font-size: 34px;
    line-height: 44px;
  }

  .banner-content p {
    font-size: 24px;
    line-height: 36px;
  }

  .trustedBy h6 {
    font-size: 18px;
    margin: 35px 0 50px 0;
  }

  .trust-logo {
    width: 50%;
    margin-bottom: 30px;
    text-align: left;
    padding: 0 20px;
    text-align: center;
  }

  .multi-dashboard h5 {
    text-align: center;
  }

  .multi-dashboard h4 {
    margin: 50px 0 35px 0;
    font-size: 32px;
  }

  .test-btn {
    margin-bottom: 50px;
  }

  .work-border {
    height: 160px;
  }

  .work-card .work-content .heading {
    font-size: 24px;
  }

  .work-card .work-content {
    padding: 15px;
  }

  .work-card .logo-section {
    width: 100px;
  }

  .work-card .logo-section img {
    height: 80px;
  }

  .btnOutline {
    height: 34px;
    padding: 6px 13px;
  }

  .testimonial {
    background-size: cover;
  }

  .testimonial {
    padding: 80px 0 100px 0;
    margin-top: 0;
  }

  .product-dropdown {
    position: absolute;
    top: 25px;
    left: -50px;
  }

  .box .col-md-5 {
    padding: 25px 30px 25px 30px;
  }

  .w-35 {
    width: 100% !important;
  }


}

@media (max-width:767px) {
  
  .modal-body {
    padding: 0 25px;
    padding-bottom: 40px;
}
  .about-bg {
    padding: 50px 0;
  }

  .blog-bg .browse-section .filters a {
    width: 160px;
  }

  .blog-bg .first-bg {
    padding: 40px 0;
  }

  .blog-bg .first-content .heading {
    font-size: 18px;
  }

  .blog-bg .first-content .para {
    font-size: 14px;
  }

  .blog-bg .blog-card .card-content {
    font-size: 12px;
    padding: 7px;
  }

  .career {
    padding: 50px 0;
  }

  .career .career-heading {
    font-size: 28px;
  }

  .career .career-content {
    font-size: 18px;
  }

  .career-card {
    padding: 20px;
  }

  .career-card .heading {
    font-size: 17px;
  }

  .work-border {
    height: 120px;
  }

  .work-card .work-content .heading {
    font-size: 14px;
  }

  .work-card .work-content {
    padding: 0 20px;
  }

  .work-card .logo-section {
    width: 110px;
    padding: 25px;
  }

  .work-card .logo-section img {
    width: 100%;
  }

  .about-bg .about-heading {
    font-size: 28px;
  }

  .about-bg .about-content {
    font-size: 14px;
  }

  .about-card {
    padding: 18px;
  }

  .about-bg .trust-logo img {
    height: 25px;
  }
  .about-bg .trust-logo .img-1 {
    width: 100%;
  }

  .home.work-border {
    height: 150px !important;
  }
  .about-bg .trust-logo.h-3 img {
    height: 35px;
}
}




.post-link{
  font-size: 18px;
  color: #272d4a;
  font-weight: 500;
  margin: 40px 0;
  display: inline-block;
  margin-top: 60px;
}
.post-title {
  background: linear-gradient(94.46deg,#1f2a66,#dc4dff 98.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 50px;
    line-height: 70px;
}
.post-date {
  font-size: 20px;
  letter-spacing: .03em;
  font-weight: 700;
  color: #272d4a;
}

.post-image {
    position: relative;
    height: auto;
    width: 100%;
    height: 194px;
    margin-top: 35px;
    margin-bottom: 40px;
}
.post-image img{
  object-fit: cover;
}
.content-h2 {
  background: linear-gradient(94.46deg,#1f2a66,#dc4dff 98.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 60px 0 16px;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
}
.content-h3 {
  color: #272d4a;
  font-weight: 700;
  font-size: 26px;
}

.content-p {
  color: #272d4ab3;
  font-size: 18px;
  margin: 16px 0;
  font-weight: 500;
}
.content-image {
  position: relative;
  width: 100%;
  height: auto;
  margin: 25px 0;
}
.bc{
  font-size: 28px;
  padding-bottom: 3px;

}
@media (min-width: 992px){
.content-image {
    margin: 50px 0;
}
}

@media (min-width: 1200px){
.content-h2 {
    margin: 80px 0 24px;
}
.content-h3 {
  margin: 40px 0 24px;
}
}
@media (min-width: 992px){
.post-image {
    height: 340px;
}
}





.career-link{
  font-size: 18px;
  color: #272d4a;
  font-weight: 500;
  margin: 40px 0;
  display: inline-block;
  margin-top: 60px;
}
.career-title {
  background: linear-gradient(94.46deg,#1f2a66,#dc4dff 98.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
}

.career-h2 {
  background: linear-gradient(94.46deg,#1f2a66,#dc4dff 98.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 60px 0 16px;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
}
.career-h3 {
  color: #272d4a;
  font-weight: 700;
  font-size: 26px;
}

.career-p {
  color: #272d4ab3;
  font-size: 18px;
  margin: 16px 0;
  font-weight: 500;
}
.bg-grad{
  background: linear-gradient(168.77deg,rgba(161,172,229,.3) 23.67%,rgba(161,172,229,.3) 95.15%),hsla(0,0%,100%,.3);
}
.bg-grad ul li{
  color: #272d4ab3;
  font-size: 18px;
}
.h-1{
  height: 30px;
}
.h-2{
  height: 30px;
}
.h-3{
  height: 25px;
}
.h-4{
  height: 40px;
}
.t-11{
  height: 40px;
}
.t-9{
  height: 50px;
}
.t-4{
  height: 25px;
}
.t-5{
  height: 30px;
}
@media (max-width: 767px){
  .post-title{
    margin-bottom: 0px;
    font-size: 32px;
    line-height: 40px;
  }
  .post-link{
    margin: 20px 0;
    margin-top: 20px;
  }
  .content-h2{
    margin: 20px 0 15px;
    font-size: 28px;
  }
  .content-p{
    word-wrap: break-word;
    font-size: 16px;
  }
  .modal-heading{
    font-size: 28px;
  }
}
.text-none{
  text-transform: none;
}
@media (min-width: 1200px){
.career-h3 {
  margin: 40px 0 24px;
}
}



@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1260px;
  }
}
